// vim:ts=2:sw=2:et:

import React from 'react'

import { QueryClientProvider, useQueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { BusProvider, useBus } from './bus-hooks.jsx'

/**
 * TODO: Describir para qué sirve este archivo
 */

export * as React from 'react'
export * as ReactDOM from 'react-dom'

export * as PropTypes from 'prop-types'

export { default as RooTypes } from './prop-types.js'

export { BusProvider, Bus, useBus } from './bus-hooks.jsx'

export function RooProvider ({ bus, queryClient, children }) {
  return (
    <QueryClientProvider client={queryClient}>
      <BusProvider bus={bus}>
        {children}
        <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
      </BusProvider>
    </QueryClientProvider>
  )
}

export function useDatasourceControl () {
  const bus = useBus()
  const client = useQueryClient()
  return React.useMemo(() => datasourceController(bus, client), [bus, client])
}

function datasourceController (bus, client) {
  // El bus en un instanceof Bus de @grupomarea/ol-qgis
  // El client es un QueryClient de @tanstack/react-query
  return {
    getQueryData: (...args) => client.getQueryData(...args),
    setQueryData: (...args) => client.setQueryData(...args),
    resetQueries: (...args) => client.resetQueries(...args),
    removeQueries: (...args) => client.removeQueries(...args),
    invalidateQueries: (...args) => client.invalidateQueries(...args),
    refreshMapSources: (...args) => bus.refreshMapSources(...args)
  }
}

export { Sudo } from './utils/Sudo.jsx'
