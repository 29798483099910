// vim:ts=2:sw=2:et:

import PropTypes from 'prop-types'

export const ViewsPropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  view: PropTypes.string.isRequired,
  View: PropTypes.elementType
})

// back-compat con prototipo caolin
export const AutoCompleteOption = PropTypes.shape({
  title: PropTypes.string.isRequired
})

// Forma del objeto "BigNumber"
export const BigNumber = PropTypes.exact({
  s: PropTypes.number.isRequired,
  e: PropTypes.number.isRequired,
  c: PropTypes.arrayOf(PropTypes.number).isRequired
})

export const BigNumericLike = PropTypes.oneOfType([
  PropTypes.number, PropTypes.bigint, BigNumber
])

/**
 * @section RooTypes
 * Prop-Types reusables para aplicaciones y plataformas dependientes,
 * incluídas utilidades para definir prop-types.
 *
 * Define los tipos de props propios de componentes de la plataforma marco roo
 *
 * Recurso útil: https://github.com/airbnb/prop-types/tree/master/src
 */
export default class RooTypes {
  /**
   * Utilidad para definir props que son uniones de varios tipos
   */
  static allOf (...types) {
    if (types.length < 2) {
      throw new Error('No tiene sentido usar allOf si no se indican tipos')
    }
    return function allTypes (...args) {
      // Mejora by @lorenzogrv sobre lo que encontré en
      // https://github.com/airbnb/prop-types/blob/master/src/and.js
      return types.find(validator => validator(...args))
    }
  }

  /**
   * Utilidad para definir props que son "nullables" (pueden ser null)
   * @todo - se usa en marosa y caolin, mantener comentado hasta necesidad
   */
  static nullable (...args) {
    return PropTypes.oneOfType([JustNull, ...args])
  }

  /**
   * El tipo "bus" reprensenta un {Bus} estándar de Roo para abstraer la
   * comunicación entre el layout y el mapa
   */
  static get bus () { return BusType }

  /**
   * Utilidad para extender la forma a validar del tipo "bus"
   */
  static busWithShape (shape = {}) {
    return this.allOf(BusType, PropTypes.shape(shape))
  }

  /**
   * Utilidad para definir un tipo "bus" con métodos concretos
   */
  static busWithMethods (...methods) {
    return this.busWithShape(
      methods.reduce((obj, name) => ({
        ...obj, [name]: PropTypes.func.isRequired
      }), {})
    )
  }

  /**
   * El tipo "user" reprensenta un {User} estándar de Roo para abstraer la
   * representación de un usuario conectado
   */
  static get user () { return UserType }

  /**
   * Utilidad para definir un indice de tipo "featureTypes"
   */
  static get featureTypes () {
    return PropTypes.objectOf(PropTypes.shape({
      Model: PropTypes.func, // instanceOf(AbstractModel)
      Viewer: PropTypes.elementType,
      // TODO ? Skeleton: PropTypes.elementType,
      // las "views" son el "menú del visor de 1 elemento"
      views: PropTypes.arrayOf(ViewsPropType),
      // En relación a menús y su contexto
      MenuProvider: PropTypes.elementType,
      Menu: PropTypes.elementType,
      ToolsProvider: PropTypes.elementType,
      Tools: PropTypes.elementType,
      // TODO ojo que esto no se usa aún
      reducer: PropTypes.func
    }))
  }

  static selectionDetailsShape ({ featureIsRequired = true } = {}) {
    return PropTypes.shape({
      // El proyecto es el parámetro obligatorio, y se trata aparte
      map: PropTypes.string.isRequired,
      // El resto de parámetros siempre son opcionales
      params: PropTypes.objectOf(PropTypes.oneOfType([
        // Tipos permitidos en los parámetros "extra"
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
      ])),
      layer: PropTypes.shape({
        name: PropTypes.string.isRequired,
        pk: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.arrayOf(PropTypes.string)
        ]).isRequired
      }).isRequired,
      feature: (t => featureIsRequired ? t.isRequired : t)(PropTypes.object)
    })
  }

  /**
   * Utilidad para definir un objecto "details" (selección feature/layer)
   */
  static get selectionDetails () {
    return this.selectionDetailsShape()
  }

  static get selectionDetailsOptionalFeature () {
    return this.selectionDetailsShape({ featureIsRequired: false })
  }

  /**
   * Utilidad para definir props que son arrays de una longitud determinada
   * @todo - se usa en marosa y caolin, mantener comentado hasta necesidad
   /
  static arrayOfLength (length) {
    return (props, propName, componentName) => {
      const array = props[propName]

      if (!Array.isArray(array)) {
        return new Error(
          `La propiedad ${propName} de ${componentName} debe ser un array`
        )
      }

      if (array.length !== length) {
        return new Error(
          `Longitud inválida: ${array.length} (se esperaba ${length}) ` +
          `de la propiedad ${propName} de ${componentName}`
        )
      }
    }
  } // */

  /**
   * Lo que sigue se usaba en caolín. Mantener comentado hasta que sea necesario
   /
  static nullOrArrayOf (type) {
    return PropTypes.oneOfType([
      nullType,
      PropTypes.arrayOf(type)
    ])
  } // */
}

/**
 * Define el tipo "null" como Prop-Type
 * @internal
 */
const JustNull = (props, propName, componentName) => {
  if (props[propName !== null]) {
    return new Error(
      `La propiedad '${propName}' de '${componentName}' debe ser NULL`
    )
  }
}

const BusType = PropTypes.shape({
  // TODO tiene sentido declarar aparte la API del EventEmitter?
  emit: PropTypes.func.isRequired,
  on: PropTypes.func.isRequired,
  off: PropTypes.func.isRequired,
  once: PropTypes.func.isRequired,
  // Métodos básicos del Bus
  get: PropTypes.func.isRequired,
  set: PropTypes.func.isRequired
})

const UserType = PropTypes.shape({
  email: PropTypes.oneOfType([JustNull, PropTypes.string.isRequired]),
  groups: PropTypes.arrayOf(PropTypes.string).isRequired,
  isSudo: PropTypes.bool.isRequired
})

/**
 * Define un Prop-Type "nullable". Back-compat con caolin/prototipo
 * @deprecated
 */
export function nullOrType (...args) {
  return PropTypes.oneOfType([JustNull, ...args])
}
