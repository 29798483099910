import React from 'react'

export const BusContext = React.createContext(null)

export function BusProvider ({ bus, children }) {
  return (
    <BusContext.Provider value={bus}>
      {children}
    </BusContext.Provider>
  )
}

export function useBus () {
  return React.useContext(BusContext)
}

const defaultBusReducer = (prev, value) => value

export function useBusGetter (bus, key, reducer = defaultBusReducer) {
  const [state, setState] = React.useState(bus.get(key))

  const eventName = React.useMemo(() => `bus:set:${key}`, [key])

  React.useEffect(() => {
    const listener = (...args) => {
      setState(prev => reducer(prev, ...args))
    }

    bus.on(eventName, listener)
    setState(bus.get(key))

    return () => {
      bus.removeListener(eventName, listener)
    }
  }, [bus, reducer, eventName, setState])

  return state
}

export function useBusSetter (bus, key) {
  return React.useCallback((val) => bus.set(key, val), [key])
}

export function useBusState (bus, key) {
  return [
    useBusGetter(bus, key),
    useBusSetter(bus, key)
  ]
}

export function useBusCache (bus, selector = (cache) => null) {
  const [state, setState] = React.useState(() => selector(bus._cache))

  useBusEvents(bus, {
    'bus:set': (cache) => setState(selector(cache))
  })

  return state
}

export function useBusEvents (bus, _events = {}, deps = []) {
  const events = React.useMemo(() => _events, deps)

  React.useLayoutEffect(() => {
    // console.info('Observar el bus')
    for (const [event, listener] of Object.entries(events)) {
      bus.on(event, listener)
    }

    // Importante limpiar listeners al desmontar el componente
    return () => {
      // console.info('Limpiar listeners de eventos')
      for (const [event, listener] of Object.entries(events)) {
        bus.removeListener(event, listener)
      }
    }
  }, [bus, events])
}
