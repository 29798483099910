// vim:ts=2:sw=2:et

import Bus from '@grupomarea/ol-qgis/Bus'

import Select from 'ol/interaction/Select'
import { click, pointerMove } from 'ol/events/condition'
// import { fromLonLat } from 'ol/proj'
// import { getCenter } from 'ol/extent'
import { Stroke, Fill, Style } from 'ol/style'

import Feature from 'ol/Feature'
import GeoJSON from 'ol/format/GeoJSON'

import MultiPolygon from 'ol/geom/MultiPolygon'
import LineString from 'ol/geom/LineString'
import Polygon from 'ol/geom/Polygon'
import Point from 'ol/geom/Point'

import { easeOut } from 'ol/easing'

// if (module.hot) {
//   module.hot.accept()
// }

const localStorage = window.localStorage
const storedProject = localStorage.getItem('proyecto')

export class HapunaBus extends Bus {
  constructor (opts = {}) {
    super(opts /* { CacheStore: MarosaCache, ...opts } */)

    // TODO esto pertecene a un paquete de orden superior
    this.geojson = new GeoJSON()

    /**
     * El mejor lugar para inicializar estados antes del primer Render
     * es aquí
     */
    // TODO hay que extraer todos estos magic strings a constantes
    this.set('map', null)
    this.set('menuOpen', false)
    this.set('mainOpen', false)
    this.set('sideOpen', true)
    this.set('kioskMode', true)
    this.set('view:proyecto', storedProject || 'AREA')
    this.setFeatureDetails({ layer: null, feature: null, geometry: null })

    this.get('view:proyecto')
    localStorage.setItem('proyecto', this.get('view:proyecto'))

    if (process.env.NODE_ENV !== 'production') window.bus = this

    // dado que proveemos un setFeature, tenemos responsabilidad de inicializar
    // su valor por defecto
    // DEPRECATED: ya no se usa, es redundante y provoca confusión
    // this.setFeature(null, { open: false, loading: true })

    this._mode = null
    this._interactions = []
    /**
     * Constantes necesarias del "info" mode
     * Ver
     * https://openlayers.org/en/latest/apidoc/module-ol_interaction_Select-Select.html
     */
    const infoHitTolerance = 150
    // TODO infoLayerFilter (layer) => layer.get('isQueryable')
    const infoFilter = (feature, layer) => layer.get('isQueryable')
    this._infoStyle = (_feature, resolution) => {
      // Por el momento es la forma de detectar si es un clúster
      const feature = _feature.get('features')?.[0] || _feature

      // Es una guarrada el split, pero de momento es lo que hay
      const layerId = feature.getId()?.split('.').shift()

      if (!layerId) {
        return console.warn('no se encuentra la capa de', { feature, _feature })
      }

      // obtenemos el "estilo actual" de la capa
      let actual = this.findLayer(layerId).getStyle()
      if (typeof actual === 'function') {
        actual = actual(_feature, resolution)
      }
      return [
        // Y nos aseguramos de incluírlo en el estilo resultantes
        ...(Array.isArray(actual) ? actual : [actual]),
        // TODO case Polygon
        new Style({ fill: new Fill({ color: 'rgba(255, 255, 255, 0.5)' }) }),
        new Style({ stroke: new Stroke({ color: 'black', width: 10 }) }),
        new Style({ stroke: new Stroke({ color: 'white', width: 5 }) })
        // TODO case Point
      ]
    }
    this._infoSelectClick = new Select({
      // multi: true,
      // layers: infoLayerFilter,
      filter: infoFilter,
      contition: click,
      infoHitTolerance,
      style: this._infoStyle
    })
    this._infoSelectHover = new Select({
      /* filter: (feature, layer) => {
        return (
          infoFilter(feature, layer) &&
          this._infoSelectClick.getFeatures().getArray()[0] !== feature
        )
      }, */
      condition: pointerMove,
      style: null // STYLES.hover
    })
    this._infoSelectClick.on('select', (event) => {
      const { selected, deselected } = event
      /* console.log('select event', {
        event, selected, deselected
      }) */
      if (selected.length) {
        if (selected.length > 1) {
          console.warn('Esto hay que pulirlo (selección multiple)', selected)
        }

        let feature = selected[0]
        const layer = this._infoSelectClick.getLayer(feature)

        if (layer.get('clusterized')) {
          const group = feature.get('features')
          if (group.length === 0) return console.warn('cluster vacío', feature)
          if (group.length > 1) {
            // zoom al cluster
            const zoom = this.mapView.getZoom()
            // el cluster debería "deshacerse" en cierto momento
            this.fitMapOn(feature.getGeometry(), {
              easing: easeOut,
              duration: 0.6 * 1000,
              // A partir de cierto nivel, no hacer zoom
              maxZoom: zoom > 19 ? undefined : zoom + (zoom < 10 ? 5 : 3)
            })
            return
          }
          feature = group[0]
        }

        // Este fragmento antes vivía la App principal
        if (!layer) {
          return console.warn('Ignorar selección (no layer)', {
            event, selected, deselected, feature, layer
          })
        }
        const layerProps = layer.getProperties()
        const { geometry, ...featureProps } = feature.getProperties()
        this.setFeatureDetails({
          layer: layerProps,
          feature: featureProps,
          geometry: this.toGeoJSONGeometry(feature, {
            featureProjection: layer.getSource().getProjection()
          })
        })

        // TODO quizás debamos deprecar esta emisión?
        this.emit('map:select:feature', { feature, layer })
      } else if (deselected.length) {
        this.clearSelection()
        // setFeatureDetails({ feature: null, geometry: null })
      } else {
        console.warn('Estoy hay que pulirlo (sobran eventos?)', event)
      }
    })
    /**
     * Constantes necesarias del "draw" mode
     * (Se utilizan las de @grupomarea/ol-qgis/Bus
     */
  }

  /* get (...args) {
    // console.warn('bus get', ...args)
    return super.get(...args)
  } */

  set (key, val) {
    // consle.warn('bus set', key, val)

    if (key === 'view:proyecto') {
      localStorage.setItem('proyecto', val)
    }

    // hacer debounce para facilitar múltiples "sets" síncronos
    if (this._cacheSetTO) clearTimeout(this._cacheSetTO)

    this._cacheSetTO = setTimeout(() => {
      this.emit('bus:set', this._cache)
      this._cacheSetTO = null
    }, 10)

    return super.set(key, val)
  }

  get map () { return this.get('map') }
  get mapView () { return this.get('map').getView() }

  toggleMenuPanel () {
    this.set('menuOpen', !this.get('menuOpen'))
  }

  toggleMainPanel () {
    this.set('mainOpen', !this.get('mainOpen'))
  }

  openMainPanel () {
    this.set('mainOpen', true)
  }

  closeMainPanel () {
    this.set('mainOpen', false)
  }

  toggleSidePanel () {
    const target = !this.get('sideOpen')
    this.set('sideOpen', target)

    // if (target === false) this.clearInfo()

    if (!this.get('kioskMode')) {
      if (target) {
        this.set('app:sizes', { primary: 65, secondary: 35 })
      } else {
        this.set('app:sizes', { primary: 100, secondary: 0 })
      }
    }
  }

  toggleKiosk (desired) {
    const target = typeof desired === 'boolean'
      ? desired
      : !this.get('kioskMode')

    this.set('kioskMode', target)

    if (target) {
      if (!!this.getFeatureDetails().feature !== this.get('sideOpen')) {
        this.toggleSidePanel()
      }
    }
  }

  setMap (map) {
    const old = this._cache.get('map')

    if (old?.resizeObserver_) {
      if (!old.resizeObserver_.dispose) {
        old.resizeObserver_.dispose = () => console.warn(
          'monckey-patch para un bug grave en ol-qgis'
        )
      }
    }

    // overloading de super()
    super.setMap(map)

    if (map) {
      map.addLayer(this._drawLayer)
      this.setToolMode('info')
    }

    return map
  }

  getFeature () {
    this.get('view:feature')
  }

  toGeoJSONGeometry (feature, opts = {}) {
    return this.geojson.writeFeatureObject(feature, {
      // dataProjection: 'EPSG:4326',
      // featureProjection: // this.getMap().getView().getProjection()
      ...opts
    }).geometry
  }

  geometryFromGeoJSON ({ type, coordinates }) {
    switch (type) {
      case 'MultiPolygon': return new MultiPolygon(coordinates)
      case 'LineString': return new LineString(coordinates)
      case 'Polygon': return new Polygon(coordinates)
      case 'Point': return new Point(coordinates)
      default: throw new Error(`Geometría no implementada: '${type}'`)
    }
  }

  setFeatureLoading (status = undefined) {
    if (status instanceof Error) return this.emit('app:feature:loaderror')
    // Por el momento no es crítico. Sólo los utiliza el Progress de init
    this.emit(status ? 'app:feature:loadstart' : 'app:feature:loadend')
  }
  // setFeatureError()

  setFeature (vo, opts = {}) {
    console.trace('setFeature??')
    const {
      loading = false,
      // Adivinando el futuro, será necesario (o no):
      open = false
    } = opts

    // TODO sería mejor opts.Model o _meta.Model similar
    if (vo !== null && !vo._meta) {
      throw new Error('Falta el _meta del vo')
      /* TODO comprobar que tiene esta pinta:
      _meta: {
        layer: layer.treename,
        layername: layer.name,
        layertitle: layer.title,
        primary: layer.pk
      }
      */
    }

    // Abrir la vista de detalle cuando existe el feature,
    // pero hacerlo de modo que no se notifique el evento
    if (vo && !this.get('sideOpen')) this.set('sidePanel', true)

    this.set('view:feature', {
      _open: open || (vo !== null),
      _loading: loading || (vo === null),
      _data: vo
    })
  }

  findLayer (layerId) {
    const map = this._cache.get('map')
    return map.getAllLayers().find((layer) => {
      return layer.getProperties().name === layerId
    })
  }

  findFeatureIn (layer, testFn) {
    return layer.getSource().getFeatures().find(testFn)
  }

  findFeature (layerId, featureId) {
    const layer = this.findLayer(layerId)

    if (typeof featureId === 'function') {
      return this.findFeatureIn(layer, featureId)
    }

    const pk = layer.get('pk')
    return this.findFeatureIn(layer, item => {
      // Este "item" es un feature de OpenLayers
      return item.get(pk) === featureId
    })
  }

  findDetailsSources (layerId, testFn) {
    const layer = this.findLayer(layerId)
    return {
      layer,
      feature: this.findFeatureIn(layer, testFn)
    }
  }

  setSelectionGeoJSON (layerId, geojson) {
    const layer = this.findLayer(layerId)
    if (!layer) throw new Error(`La capa '${layerId}' no existe`)

    const feature = new Feature({
      geometry: this.geometryFromGeoJSON(geojson.geometry),
      ...geojson.properties
    })

    // Es necesario reproyectar el feature, GeoJSON siempre está en 4326
    feature.getGeometry().transform(
      'EPSG:4326',
      this.get('map').getView().getProjection()
    )

    this.setFeatureDetails({
      layer: layer?.getProperties(),
      feature: feature?.getProperties(),
      geometry: geojson.geometry
    })
    // Es sucio, pero necesario para establecer correctamente la selección
    // para marcar correctamente el feature
    this.setSelection(feature, {
      maxZoom: 18,
      callback: (completed) => {
        if (!completed) return
        console.warn('THIS SHIT RUNS')
        const pk = layer.get('pk')
        const trueFeature = this.findFeatureIn(layer, item => {
          return item.get(pk) === feature.get(pk)
        })
        if (!trueFeature) {
          return layer.getSource().once('featuresloadend', () => {
            this.setSelection(this.findFeature(layerId, feature.get(pk)), {
              maxZoom: 18
            })
          })
        }
        this.setSelection(trueFeature, { maxZoom: 18 })
      }
    })
  }

  setSelectionBy (layerId, testFn) {
    const { layer, feature } = this.findDetailsSources(layerId, testFn)
    this.setFeatureDetails({
      layer: layer?.getProperties(),
      feature: feature?.getProperties(),
      geometry: feature
        ? this.toGeoJSONGeometry(feature, {
          featureProjection: layer.getSource().getProjection()
        })
        : null
    })
    if (!feature) {
      return console.warn('No se ha encontrado el feature de', layerId)
    }
    this.setSelection(feature)
  }

  setSelection (feature, fitMapOpts) {
    this._infoSelectClick.getFeatures().clear()
    this._infoSelectClick.getFeatures().push(feature)
    console.info('Estableciendo selección a', { feature })
    this.fitMapOn(feature.getGeometry(), fitMapOpts)
  }

  setFeatureDetails (opts = {}) {
    // este call es lo que hace super.setFeatureDetails(opts)
    this.set('view:details', {
      ...this.getFeatureDetails(),
      map: this.get('view:proyecto'),
      ...opts
    })

    // Lo que sigue es una customización para hapuna
    if (this.get('kioskMode')) {
      // Abrir y cerrar los detalles cuando el modo kiosko está activado
      if (!!opts.feature !== this.get('sideOpen')) this.toggleSidePanel()
    } else {
      // Abrir el panel lateral sólo al seleccionar
      if (opts.feature && !this.get('sideOpen')) this.toggleSidePanel()
    }
  }

  setFeatureDetailsFilter (filter = {}) {
    this.set('view:details', {
      ...this.getFeatureDetails(),
      filter
    })
  }

  getFeatureDetails () {
    return this.get('view:details')
  }

  setMapPadding (padding) {
    const map = this._cache.get('map')
    map.getView().padding = padding
  }

  refreshMapSources (filter = () => true) {
    this.get('map').getLayers().forEach(function refresh (layer) {
      if (layer.getLayers) {
        // console.log('La capa', layer.get('name'), 'tiene subcapas')
        return layer.getLayers().forEach(refresh)
      }
      if (!layer.getSource) {
        // console.log('La capa', layer.get('name'), 'no tiene source')
        return
      }
      if (!filter(layer)) {
        // console.log('Omitir la capa', layer.get('name'), 'del refresco')
        return
      }
      console.info('Refrescar el source de la capa', layer.get('name'))
      let source = layer.getSource()
      if (layer.get('clusterized')) source = source.getSource()
      source.refresh()
    })
  }
}

/*
export class MarosaCache {
  constructor () {
    const _store = Object.create(null)

    // console.warn('CACHE CONSTRUIDA')

    Object.defineProperties(this, {
      get: {
        value: (key) => _store[key],
        writable: false
      },
      set: {
        value (key, val) {
          // console.error('GUARDAR', key, val)
          _store[key] = val
        },
        writable: false
      },
      getState: {
        value: () => ({ ..._store }),
        writable: false
      }
    })
  }
}
*/
