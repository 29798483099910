import { useBus } from '@grupomarea/roo-www-abcs/bus-hooks'

import Stack from '@mui/material/Stack'

export function Sudo ({ sx, children }) {
  const bus = useBus()

  if (!bus.get('user')?.isSudo) return null

  return (
    <Stack
      sx={{
        position: 'relative',
        '&::after': {
          content: '"Este elemento sólo es visible para Admins de Gisgal"',
          display: 'block',
          position: 'absolute',
          bottom: '-.4rem',
          bgcolor: 'white',
          color: 'var(--marea-dark)',
          fontSize: '.6rem',
          padding: '0 .5em'
        },
        boxSizing: 'border-box',
        padding: 2,
        outline: '1px dashed var(--marea-red)',
        border: '1px dashed var(--marea-light)',
        my: 2,
        ...sx
      }}
    >
      {children}
    </Stack>
  )
}
