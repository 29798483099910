import PropTypes from 'prop-types'

import { styled } from '@mui/material/styles'

import MuiAppBar from '@mui/material/AppBar'

export const StyledBar = styled(MuiAppBar, {
  // Lo que sigue evita que estas props lleguen como atributos al elemento HTML
  shouldForwardProp: (prop) => ![
    'open', 'zIndexDelta', 'widthDelta'
  ].includes(prop)
})(({ theme, open, zIndexDelta, widthDelta }) => {
  return {
    zIndex: theme.zIndex.drawer + zIndexDelta,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
      width: `calc(100% - ${widthDelta}px)`,
      marginRight: `${widthDelta}px`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      })
    })
  }
})

StyledBar.propTypes = {
  zIndexDelta: PropTypes.number.isRequired,
  widthDelta: PropTypes.number.isRequired
}

/**
 * Se utiliza para maquetar la cabecera
 */
export function Header ({ drawerWidth, ...etc }) {
  return (
    <StyledBar
      component='header' // mejor ser explícitos con la semántica
      widthDelta={drawerWidth}
      zIndexDelta={1}
      {...etc}
    />
  )
}

/**
 * Se decició usar para simplificar la maquetación del footer
 */
export function Footer ({ drawerWidth, ...etc }) {
  return (
    <StyledBar
      component='footer'
      widthDelta={drawerWidth}
      zIndexDelta={-1}
      {...etc}
    />
  )
}

/**
 * Ambos componentes son intercambiables.
 * La prop "drawerWidth" se mantiene por razones históricas, aunque
 * semánticamente se entiende mal lo que hace
 */
Header.propTypes = Footer.propTypes = {
  open: PropTypes.bool,
  drawerWidth: PropTypes.number.isRequired
}
