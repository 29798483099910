// vim:ts=2:sw=2:et

import JSONbig from 'json-bigint'

const JSONparser = JSONbig({ strict: true })

/* global fetch */

export const api = {
  get (uri, data = {}, opts = {}) {
    if (uri.includes('?')) {
      throw new TypeError('La URI no debe contener el carácter "?"')
    }

    const qs = computeQueryString(data)
    return fetch(`/gis${uri}?${qs}`, {
      ...opts,
      headers: {
        Accept: 'application/json'
      }
    })
  },
  async post (uri, data) {
    const response = await fetch(`/gis${uri}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSONbig.stringify(data)
    })
    if (process.env.NODE_ENV !== 'test') return response

    response.requestMethod = 'POST'
    response.sentData = data
    return response
  },
  put (uri, data) {
    return fetch(`/gis${uri}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSONbig.stringify(data)
    })
  },
  delete (uri, data = {}) {
    return fetch(`/gis${uri}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSONbig.stringify(data)
    })
  }
}

/**
 * Las que siguen tendrían más sentido como "fetch utils" o similar
 */

export function computeQueryString (filter) {
  return Object.entries(filter)
    .map(([key, val]) =>
      encodeURIComponent(key) + '=' + encodeURIComponent(val)
    )
    .join('&')
}

export async function receive (response, transform = (data) => data) {
  if (!response.ok) {
    let error = new Error(`Error ${response.status}`)

    if (response.status === 422) {
      console.warn('Utilizando JSON.parse nativo (sin soporte BigInt)')
      const { message, error: _error } = await response.json()
      error = Object.entries(_error).reduce((e, [key, val]) => {
        e[key] = val
        return e
      }, new Error(message))
    }

    error.status = response.status
    error.response = response

    throw error
  }
  return transform(JSONparser.parse(await response.text()))
}
