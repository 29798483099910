import Stack from '@mui/material/Stack'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'

export function Text ({
  secondary,
  sx,
  ...props
}) {
  return (
    <Typography
      sx={
        secondary
          ? { color: 'text.secondary', ...sx }
          : sx
      }
      variant={secondary ? 'body2' : 'body1'}
      {...props}
    />
  )
}

export function Title (props) {
  return <Typography variant='h3' {...props} />
}

export function Title2 (props) {
  return <Typography variant='h6' {...props} />
}

export function Line ({ transparent = false, ...props }) {
  return (
    <Divider
      sx={{
        my: transparent ? 1 : 2.5,
        ...(transparent
          ? { color: 'transparent', borderColor: 'transparent' }
          : {}
        )
      }}
      aria-hidden='true'
      {...props}
    />
  )
}

export function Info ({ title, children, ...props }) {
  return (
    <Alert severity='info' variant='outlined' {...props}>
      {title && <AlertTitle>{title}</AlertTitle>}
      {children}
    </Alert>
  )
}

export function Warn ({ title, children, onAccept, onCancel, ...props }) {
  return (
    <Alert
      severity='warning'
      {...props}
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      {children}
      {(onAccept || onCancel) && (
        <Stack
          sx={{ mt: 2, ml: 'auto', display: 'block' }}
          spacing={1}
          direction='row'
        >
          {onAccept && (
            <Button
              color='primary'
              variant='contained'
              onClick={onAccept}
            >
              Continuar
            </Button>
          )}
          {onCancel && (
            <Button
              color='secondary'
              variant='contained'
              onClick={onCancel}
            >
              Cancelar
            </Button>
          )}
        </Stack>
      )}
    </Alert>
  )
}

export function Link (props) {
  return <Button component='a' rel='noreferrer' target='_blank' {...props} />
}
