import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar/Toolbar'
import IconButton from '@mui/material/IconButton/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import { Stack } from '@mui/material'

import { Header } from './AppBar'

/**
 * Mientras que "roo" debe tener un AppHeader y es lícito,
 * la cuestión del "drawerWidth" es concreta de hapuna y no debería estar aquí
 */
export default function AppHeader ({
  bus,
  open,
  drawerWidth,
  onClickMenu,
  logo,
  MenuButtonProps = {},
  controlButtons,
  children,
  ...etc
}) {
  return (
    <Header
      open={open}
      drawerWidth={drawerWidth}
      position='static'
      sx={{
        backgroundColor: 'black',
        // TODO color por defecto a --marea-light?
        '& .MuiButtonBase-root.Mui-disabled': {
          color: 'var(--marea-dark)' // color: 'rgba(255, 255, 255, .5)'
        }
      }}
      {...etc}
    >
      <Toolbar
        variant='dense'
        sx={{
          justifyContent: 'space-between',
          '& > *': { ml: 2 },
          '& > :first-child': { ml: 0 }
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <IconButton
            color='inherit'
            aria-label='open main menu'
            onClick={onClickMenu}
            edge='start'
            sx={{ mr: 2 }}
            className='RooHamburger'
            {...MenuButtonProps}
          >
            <MenuIcon />
          </IconButton>
          <img
            src={logo}
            alt='Gisgal - Soluciones GIS Galicia'
            title='Gisgal - Soluciones GIS Galicia'
            className='logo'
            style={{ maxWidth: '100px', alignSelf: 'center' }}
          />
          {/* no se si es la mejor solucion pero hay que meter botones aquí */}
          <Stack
            direction='row'
            ml={1}
            spacing={2}
            alignItems='center'
            className='RooHeadControl-deprecated'
          >
            {controlButtons}
          </Stack>
        </Box>
        {children}
      </Toolbar>
    </Header>
  )
}

AppHeader.propTypes = {
  open: PropTypes.bool,
  onClickMenu: PropTypes.func
}
