import { createTheme } from '@mui/material/styles'
import { esES } from '@mui/material/locale'

const theme = createTheme({
  typography: {
    fontSize: 12,
    body1: {
      // fontSize: '0.9rem'
    }
  },
  components: {
    MuiInputBase: {
      defaultProps: {
        disableInjectingGlobalStyles: true
      }
    }
  }
}, esES)

export default theme
