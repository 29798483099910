import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

export default function Rights ({ sx }) {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Typography sx={sx}>
      {!isSmall && '© '}
      <Link
        rel='noreferrer'
        href='https://gisgal.com'
        target='_blank'
        variant='inherit'
        color='lightgray'
        underline='hover'
      >
        {isSmall ? '©' : 'Soluciones GIS Galicia S.L.'}
      </Link>
    </Typography>
  )
}

export { Rights as Copyright }
