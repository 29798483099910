// vim:ts=2:sw=2:et
import React from 'react'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import MuiSkeleton from '@mui/material/Skeleton'
import LinearProgress from '@mui/material/LinearProgress'
import MuiStack from '@mui/material/Stack'

export { default as Box } from '@mui/material/Box'

export function VerticalStack ({ sx, ...props }) {
  return (
    <MuiStack
      direction='column'
      sx={{
        height: '100%',
        '& > :last-child': { flexGrow: 1, overflowY: 'auto' }
      }}
      {...props}
    />
  )
}

export function Stack ({ direction, sx, ...props }) {
  return (
    <MuiStack
      direction={direction}
      sx={sx}
      {...props}
    />
  )
}

export function ViewerContainer ({ skeleton = false, sx, ...props }) {
  if (skeleton) {
    return (
      <ViewerContainer {...props}>
        <MuiSkeleton />
      </ViewerContainer>
    )
  }
  return (
    <Container maxWidth='xl' disableGutters sx={{ px: 2, ...sx }} {...props} />
  )
}

export function _GridContainer (props, ref) {
  return <Grid ref={ref} container {...props} />
}
export const GridContainer = React.forwardRef(_GridContainer)

export function GridItem (props) {
  return <Grid item {...props} />
}

export function Skeleton ({ loading = true, ...props }) {
  return (
    <MuiSkeleton
      animation={loading ? 'pulse' : false}
      {...props}
    />
  )
}

export function DeterminateLoading (props) {
  return (
    <LinearProgress variant='determinate' {...props} />
  )
}

export { Line, Info, Warn } from '@grupomarea/roo-www-abcs/Text'
