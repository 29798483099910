import { EventEmitter } from 'events'

import Select from 'ol/interaction/Select'
import Draw from 'ol/interaction/Draw'
import { Vector as VectorSource } from 'ol/source'
import { Vector as VectorLayer } from 'ol/layer'
import { click, pointerMove } from 'ol/events/condition'
import { easeIn } from 'ol/easing'
import { Stroke, /* Fill, */ Style } from 'ol/style'

import GeoJSON from 'ol/format/GeoJSON'

class BusCache {
  constructor () {
    const _store = Object.create(null)

    Object.defineProperties(this, {
      get: {
        value: (key) => _store[key],
        writable: false
      },
      set: {
        value: (key, val) => { _store[key] = val },
        writable: false
      }
    })
  }
}

// see https://stackoverflow.com/a/27388993/1894803
function hoverPointer (event) {
  let hit = false
  this.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
    hit = hit || layer.get('isQueryable')
  })
  this.getTargetElement().style.cursor = hit ? 'pointer' : ''
}

export default class Bus extends EventEmitter {
  constructor (conf = {}) {
    const {
      init = (map) => null,
      CacheStore = BusCache
    } = conf

    super()

    this.geojson = new GeoJSON()

    this._cache = new CacheStore()
    this._cache.set('init', init)

    this._cache.set('onLayerVisible', (event) => {
      const action = event.oldValue ? 'hide' : 'show'
      this.emit(`map:${action}:layer`, event, event.target)
    })

    this._mode = null
    this._interactions = []

    /**
     * Constantes necesarias del "info" mode
     * Ver
     * https://openlayers.org/en/latest/apidoc/module-ol_interaction_Select-Select.html
     */
    const infoHitTolerance = 150
    // TODO infoLayerFilter (layer) => layer.get('isQueryable')
    const infoFilter = (feature, layer) => layer.get('isQueryable')
    this._infoStyle = (_feature, resolution) => {
      // Por el momento es la forma de detectar si es un clúster
      const feature = _feature.get('features')?.[0] || _feature

      // Es una guarrada el split, pero de momento es lo que hay
      const layerId = feature.getId()?.split('.').shift()

      if (!layerId) {
        return console.warn('no se encuentra la capa de', { feature, _feature })
      }

      // obtenemos el "estilo actual" de la capa
      let actual = this.findLayer(layerId).getStyle()
      if (typeof actual === 'function') {
        actual = actual(_feature, resolution)
      }
      return [
        // Y nos aseguramos de incluírlo en el estilo resultantes
        ...(Array.isArray(actual) ? actual : [actual]),
        // TODO case Polygon
        new Style({ stroke: new Stroke({ color: 'black', width: 8 }) }),
        new Style({ stroke: new Stroke({ color: 'white', width: 3 }) })
        // TODO case Point
      ]
    }

    this._infoSelectClick = new Select({
      // multi: true,
      // layers: infoLayerFilter,
      filter: infoFilter,
      contition: click,
      infoHitTolerance,
      style: this._infoStyle // || STYLES.selected
    })

    this._infoSelectHover = new Select({
      /* filter: (feature, layer) => {
        return (
          infoFilter(feature, layer) &&
          this._infoSelectClick.getFeatures().getArray()[0] !== feature
        )
      }, */
      condition: pointerMove,
      style: null // STYLES.hover
    })

    this._infoSelectClick.on('select', (event) => {
      const { selected, deselected } = event
      /* console.log('select event', {
        event, selected, deselected
      }) */
      if (selected.length) {
        if (selected.length > 1) {
          console.warn('Esto hay que pulirlo (selección multiple)', selected)
        }

        let feature = selected[0]
        const layer = this._infoSelectClick.getLayer(feature)

        if (layer.get('clusterized')) {
          const group = feature.get('features')
          if (group.length === 0) return console.warn('cluster vacío', feature)
          if (group.length > 1) {
            // zoom al cluster
            // TODO a partir de cierto nivel, no hacer zoom
            // el cluster debería "deshacerse" en cierto momento
            this.fitMapOn(feature.getGeometry(), {
              zoom: this.mapView.getZoom() + 3
            })
            return
          }
          feature = group[0]
        }

        // Este fragmento antes vivía la App principal
        if (!layer) {
          return console.warn('Ignorar selección (no layer)', {
            event, selected, deselected, feature, layer
          })
        }
        const layerProps = layer.getProperties()
        const { geometry, ...featureProps } = feature.getProperties()
        this.setFeatureDetails({
          layer: layerProps,
          feature: featureProps,
          geometry: this.toGeoJSONGeometry(feature, {
            featureProjection: layer.getSource().getProjection()
          })
        })

        // TODO quizás debamos deprecar esta emisión?
        this.emit('map:select:feature', { feature, layer })
      } else if (deselected.length) {
        this.clearSelection()
        // setFeatureDetails({ feature: null, geometry: null })
      } else {
        console.warn('Estoy hay que pulirlo (sobran eventos?)', event)
      }
    })

    /**
     * Constantes necesarias del "draw" mode
     */
    const drawSource = new VectorSource({ wrapX: false })
    this._drawLayer = new VectorLayer({
      className: 'drawLayer',
      key: 'drawLayer',
      name: 'drawLayer',
      type: 'internal',
      title: 'drawLayer',
      isGroup: false,
      source: drawSource
    })
    this._drawPoint = new Draw({
      type: 'Point',
      source: drawSource,
      maxPoints: 1 /* ,
      style: [
        new Style({ fill: new Fill({ color: 'rgba(255, 255, 255, 0.5)' }) }),
        new Style({ stroke: new Stroke({ color: 'black', width: 10 }) }),
        new Style({ stroke: new Stroke({ color: 'white', width: 5 }) })
      ] */
    })
    this._drawPoint.on('drawstart', (event) => {
      drawSource.clear()
      const coords = event.feature.getGeometry().getCoordinates()
      console.log('Draw: ', { coords, event })
      if (coords) this.emit('map:draw:point', coords)
    })
  }

  get (key) {
    // console.warn('get', key, this._cache.get(key))
    return this._cache.get(key)
  }

  set (key, val) {
    this._cache.set(key, val)
    this.emit(`bus:set:${key}`, this._cache.get(key))
  }

  toGeoJSONGeometry (feature, opts = {}) {
    return this.geojson.writeFeatureObject(feature, {
      // dataProjection: 'EPSG:4326',
      // featureProjection: // this.getMap().getView().getProjection()
      ...opts
    }).geometry
  }

  findLayer (layerId) {
    const map = this._cache.get('map')
    return map.getAllLayers().find((layer) => {
      return layer.getProperties().name === layerId
    })
  }

  setFeatureDetails (opts = {}) {
    // Me preocupa ese "view:proyecto": Es realmente un "abc"?
    this.set('view:details', {
      ...this.getFeatureDetails(),
      map: this.get('view:proyecto'),
      ...opts
    })
  }

  getFeatureDetails () {
    return this.get('view:details')
  }

  setMap (map) {
    const old = this._cache.get('map')

    if (old) {
      console.info('Destruyendo el mapa anterior')
      const container = old.getTargetElement()
      if (container) container.innerHTML = ''
      old.resizeObserver_?.disconnect()
      old.setTarget(undefined)
      old.dispose()
    }

    this._cache.set('map', map)

    if (map) {
      const listener = this.get('onLayerVisible')

      ;(function iterate (layers) {
        for (const layer of layers) {
          // layer.un('change:visible', listener)
          // console.log('Emitir change:visible para', { layer })
          if (layer.getVisible()) {
            this.emit('map:show:layer', null, layer)
          } else {
            this.emit('map:hide:layer', null, layer)
          }
          layer.on('change:visible', listener)
          if (layer.getLayers) iterate.call(this, layer.getLayers().getArray())
        }
      }).call(this, map.getLayers().getArray())

      map.on('loadstart', this.emit.bind(this, 'map:loadstart'))
      map.on('loadend', this.emit.bind(this, 'map:loadend'))

      map.on('prerender', this.emit.bind(this, 'map:prerender'))
      map.on('postrender', this.emit.bind(this, 'map:postrender'))
      map.on('rendercomplete', this.emit.bind(this, 'map:rendercomplete'))

      this._cache.get('init')(this, map)
    }

    this.emit('bus:set:map', map)
    this.setToolMode('info')
  }

  refreshMapSources () {
    /* for (const layer of map.getLayers().getArray()) {
      layer.getSource().refresh()
    } */
    this.emit('source:refresh')
  }

  setToolMode (mode) {
    if (this._mode === mode) return

    const map = this._cache.get('map')
    if (!map) return // Puede ocurrir con el HMR

    console.info('Cambiando el modo del mapa de', this._mode, 'a', mode)

    for (const interaction of this._interactions) {
      map.removeInteraction(interaction)
    }
    this._interactions = []

    // Desactivación del modo anterior
    switch (this._mode) {
      case 'draw': {
        // NOTA: ahora mantenemos la capa para poder ver lo dibujado
        // const source = this._drawLayer.getSource()
        // if (source.getFeatures().length < 1) {
        //   console.info('Eliminar la capa draw (no tiene features)')
        //   // map.removeLayer(this._drawLayer)
        // } else {
        //   // source.clear()
        //   console.info('Mantener la capa de dibujo (tiene features)')
        // }
        break
      }
      case 'info':
        map.un('pointermove', hoverPointer)
        this._infoSelectClick.setActive(false)
        console.info('Desactivar interacción SelectClick')
        break
      case 'hide':
        map.getTargetElement().style.display = ''
        break
    }

    // Activación del modo nuevo
    switch (mode) {
      case 'draw':
        this._interactions = [this._drawPoint, this._infoSelectClick]
        break
      case 'info':
        map.on('pointermove', hoverPointer)
        this._infoSelectClick.setActive(true)
        console.info('Activar interacción SelectClick')
        this._interactions = [this._infoSelectClick, this._infoSelectHover]
        break
      case 'hide':
        map.getTargetElement().style.display = 'none'
        break
      default:
        console.warn('Modo inválido para el mapa:', mode)
        throw new ReferenceError('Omg!')
    }
    for (const interaction of this._interactions) {
      map.addInteraction(interaction)
    }
    this._mode = mode
    this.set('tool:mode', mode)
  }

  clearSelection () {
    switch (this._mode) {
      case 'info': this.clearInfo(); break
      case 'draw': this.clearDraw(); break
    }
    this.setFeatureDetails({ feature: null, geometry: null })
  }

  clearInfo () {
    this._infoSelectClick.getFeatures().clear()
  }

  clearDraw () {
    this._drawLayer.getSource().clear()
    // or this._drawSource.clear() si fuese necesario
  }

  async fit (geomOrExtent, opts) {
    return new Promise((resolve, reject) => this.fitMapOn(geomOrExtent, {
      ...opts,
      callback (completed) {
        if (completed) return resolve()
        reject(new Error('Animación cancelada'))
      }
    }))
  }

  fitMapOn (geomOrExtent, {
    // → nos quedamos con 2 opciones de momento: duration y padding
    // → el resto de opcciones se reenvian como opciones del método de ol
    // @see https://openlayers.org/en/latest/apidoc/module-ol_View-View.html#fit
    // La opción "padding" NUNCA se reenvía a fit, modifica el padding del View
    duration = 1000,
    padding,
    ...etc
  } = {}) {
    const map = this._cache.get('map')

    if (!map) throw new Error('No está inicializado')

    const view = map.getView()

    if (view.getAnimating()) view.cancelAnimations()

    if (padding) view.padding = padding

    view.fit(geomOrExtent, { easing: easeIn, duration, ...etc })
  }
}
