// vim:ts=2:sw=2:et

import React from 'react'

import { useQuery, useInfiniteQuery } from '@tanstack/react-query'

import { api as wfs3, receive } from './wfs3'

/**
 * Utils
 */
function fetcher (uri, data, transform) {
  return (queryContext) => wfs3
    .get(uri, data, { signal: queryContext.signal })
    .then(response => receive(response, transform))
}

/**
 * Transformers
 */
const asWFS3List = ({ features, numberMatched, numberReturned }) => ({
  list: features.map(f => f.properties),
  total: numberMatched, // TODO este total no está bien
  length: numberReturned
})
/* const asWFS3ModelList = (Model) => {
  return ({ features, numberMatched, numberReturned }) => ({
    list: features.map(f => new Model(f.properties)),
    total: numberMatched, // TODO este total no está bien
    length: numberReturned
  })
} */
const asWFS3Item = (feature) => feature.properties
/* const asWFS3ModelItem = (Model) => (feature) => new Model(
  feature.properties,
  feature.geometry
) */

/**
 * Utilidades de creación de opciones de queries/mutations aka ACCIONES
 */

export function listWFS3CollectionQueryKey (Model, filters) {
  const collection = typeof Model === 'string' ? Model : Model.collection
  return filters
    ? ['wfs3', collection, 'list', filters]
    : ['wfs3', collection, 'list']
}
export function listWFS3CollectionQuery (Model, filters = {}, {
  originalFormat = false,
  ...queryOpts
} = {}) {
  const [collection, queryFn] = typeof Model === 'string'
    ? [
        Model,
        fetcher(
          `/wfs3/collections/${Model}/items`, filters,
          originalFormat ? data => data : asWFS3List
        )
      ]
    : [
        Model.collection,
        (queryContext) => Model
          .findAll(filters, { signal: queryContext.signal })
          // TODO no mola pero hay código que se espera esta estructura
          .then(list => ({ list, total: list.length, length: list.length }))
      ]

  return {
    queryKey: listWFS3CollectionQueryKey(collection, filters),
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    /* Si lo asumimos por defecto, nunca estará "loading"
    placeholderData: {
      list: [],
      total: null,
      length: null
    }, */
    ...queryOpts
  }
}

export function getWFS3CollectionItemQueryKey (Model, id, params) {
  const collection = typeof Model === 'string' ? Model : Model.collection
  return id
    ? params
      ? ['wfs3', collection, 'item', id, params]
      : ['wfs3', collection, 'item', id, params]
    : ['wfs3', collection, 'item']
}
export function getWFS3CollectionItemQuery (
  Model, id, params = {}, queryOpts = {}
) {
  const [collection, queryFn] = typeof Model === 'string'
    ? [
        Model,
        fetcher(`/wfs3/collections/${Model}/items/${id}.json`, params, asWFS3Item)
      ]
    : [
        Model.collection,
        ({ signal }) => Model.findOne(
          { [Model.primary]: id, ...params }, { signal }
        )
      ]

  return {
    queryKey: getWFS3CollectionItemQueryKey(collection, id, params),
    queryFn,
    // NOTA @lorenzogrv: Decidí específicamente seguir usando refetchOnFocus
    // pero @IagoGarcia decidió desactivarlo de igual manera
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    ...queryOpts
  }
}

/**
 * Hooks
 */
export function useWFS3Collection (Model, filters = {}, opts) {
  return useQuery(listWFS3CollectionQuery(Model, filters, opts))
}

export function useWFS3HugeCollection (Model, filters, opts = {}) {
  const { limit, offset, ...noPageFilters } = filters

  const [collection, queryFn] = React.useMemo(() => {
    return typeof Model === 'string'
      ? [
          Model,
          fetcher(`/wfs3/collections/${Model}/items`, filters, asWFS3List)
        ]
      : [
          Model.collection,
          (queryContext) => {
            // TODO no está claro cómo carajo está capturando el limit/offset
            // console.warn('Query de Huge', { filters, queryContext })
            return Model
              .findAll(filters, { signal: queryContext.signal })
              // TODO no mola pero hay código que se espera esta estructura
              .then(list => ({ list, total: list.length, length: list.length }))
          }
        ]
  }, [Model, filters])

  // TODO la única forma de "contar" los features totales, hasta que fixeen el
  // servicio WFS3, es usar el servicio WFS. Algo del estilo:
  // https://localhost/gis/CAOLIN/
  // ?service=WFS
  // &request=GetFeature
  // &typename=punto_volumen
  // &resultType=hits
  // &outputformat=application/json

  // https://tanstack.com/query/latest/docs/react/guides/infinite-queries
  return useInfiniteQuery({
    queryKey: ['wfs3', collection, 'list', noPageFilters],
    queryFn,
    // queryFn: ({ pageParam }) => fetcher(`/collections/${collection}/items`, { ...filter, offset: pageParam || 0 }, transform),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.list.length < filters.limit) return undefined
      // console.warn('calcular nextPage', { lastPage, pages }, pages.length * filters.limit)
      return pages.length * filters.limit
    },
    getPreviousPageParam: (firstPage, allPages) => {
      // console.warn('calcular prevPage', { firstPage, allPages })
    },
    refetchOnWindowFocus: false,
    // keepPreviousData: true,
    ...opts
  })
}

export function useWFS3Item (Model, id, params = {}, opts = {}) {
  return useQuery(getWFS3CollectionItemQuery(Model, id, params, opts))
}

export function useWFS3Model (
  Model,
  params = {},
  { action = 'findAll', ...opts } = {}
) {
  return useQuery({
    queryKey: ['wfs3', Model.collection, action, params],
    queryFn ({ signal, ...queryContext }) {
      if (typeof Model[action] !== 'function') {
        throw new TypeError(`El modelo ${Model} no tiene un método #${action}`)
      }
      return Model[action](params, { signal })
    },
    retry: (failureCount, error) => (
      console.log(error) && error instanceof TypeError
        ? false
        : failureCount < 5
    ),
    refetchOnWindowFocus: false,
    ...opts
  })
}
